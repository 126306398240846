@tailwind base;
@tailwind components;
@tailwind utilities;

/* Alerts */

.alert.success {
  @apply bg-success-100 text-success-700;
}

.alert.warning {
  @apply bg-warning-100 text-warning-700;
}

.alert.danger {
  @apply bg-danger-100 text-danger-700;
}

.alert.info {
  @apply bg-info-100 text-info-700;
}

/* Badges */

.badge.success {
  @apply bg-success-100 text-success-700;
}

.badge.warning {
  @apply bg-warning-100 text-warning-700;
}

.badge.danger {
  @apply bg-danger-100 text-danger-700;
}

.badge.placeholder {
  @apply text-transparent bg-gray-200;
}

.badge.default {
  @apply bg-info-100 text-info-700;
}

.badge.light {
  @apply text-gray-700 bg-gray-100;
}

.badge.disabled {
  @apply text-gray-300 bg-gray-100;
}

.badge.donation_type_dbd {
  @apply bg-donation-type-300 text-donation-type-900;
}

.badge.donation_type_dcd {
  @apply bg-donation-type-500 text-donation-type-100;
}

.badge.session_type_clinical {
  @apply bg-session-type-300 text-session-type-900;
}

.badge.session_type_training {
  @apply bg-session-type-500 text-session-type-100;
}

/* Avatars */

.avatar.success {
  @apply bg-success-100 text-success-700;
}

.avatar.warning {
  @apply bg-warning-100 text-warning-700;
}

.avatar.danger {
  @apply bg-danger-100 text-danger-700;
}

.avatar.default {
  @apply bg-info-100 text-info-700;
}

/* Buttons */

.button.success {
  @apply text-success-100 bg-success-700 disabled:bg-success-300;
}

.button.warning {
  @apply text-warning-100 bg-warning-700 disabled:bg-warning-300;
}

.button.danger {
  @apply text-white bg-danger-700 disabled:bg-danger-300;
}

.button.light {
  @apply text-light-600 bg-light-200 disabled:bg-light-100;
}

.button.default {
  @apply text-white bg-info-700 disabled:bg-info-300;
}

.button.excel {
  @apply text-white bg-[#1D693F] disabled:bg-[#58cf8d];
}
